define({
  root: ({
    new_order_document: 'Elastic Order',
    your_order_document: 'Your Order',
    no_quantities: 'You must have a selected quantity of at least 1 in order to submit an order.',
    no_customer_number: 'Please select a Billing Address for ',
    no_location_number: 'Please select a Shipping Address for ',
    no_arrival_date: 'Please select a Start Ship Date for',
    no_cancel_date: 'Please select a Cancel Date for',
    invalid_cancel_date: 'Please Select a valid cancel date.',
    invalid_start_ship_date: 'Please select a valid Start Ship Date for',
    no_po_number: 'Please enter a PO Number for',
    po_number_exceeds_limit: 'PO Number for ### exceeds the limit of ###### characters.',
    po_number_duplicate: 'PO Numbers must be unique for each order.',
    invalid_po_number: 'Please enter a valid PO number',
    invalid_page_comment: 'Please enter a valid Comment',
    products_not_available: 'You have products in your cart which will not be available by your selected ship date.',
    already_submitted: 'This order has already been submitted.',
    already_review: 'You have already submitted this order to your rep, it needs to be reviewed.',
    shared_with: 'The document has been shared with a dealer. It is read only and cannot be submitted',
    dealer_submit: 'The document is pending review by your rep and cannot be submitted',
    page: 'Page',
    shipment: 'Shipment',
    external_field: 'Please select ### for ',
    credit_hold_explanation: 'With your current account status you are able to create an order, but you will not be able to submit. Please contact your Credit Manager.',
    catalog_min_failure: 'Must have at least ### units for this catalog.',
    catalog_max_failure: 'Exceeded limit of ### units for this catalog.',
    catalog_min_amount_failure: 'The min total order amount for this catalog is ###.',
    catalog_max_amount_failure: 'The max total order amount for this catalog is ###.',
    catalog_price_group_failure: 'Order can not be placed due to missing price group.',
    catalog_requires_embellishments: 'All items must be personalized.',
    catalog_requires_embellishments_customized: 'All items must be customized.',
    arrive_on_is_in_past: 'The Start Ship Date is in the past for ###. Please update this to be today or in the future.',
    shipping_account_number_invalid: 'Please enter a valid shipping account number',
    document_renamed: 'Order renamed.',
    document_could_not_be_renamed: 'Order could not be renamed',
    has_placeholder_allocations: 'Some items are not available by your requested ship date. In order to continue, this issue must be resolved.',
    shipment_min_amount_failure: 'The minimum total amount per shipment is ###',
    shipment_min_quantity_failure: 'The minimum total quantity per shipment is ### units.',
    shipment_max_amount_failure: 'The maximum total amount per shipment is ###',
    shipment_max_quantity_failure: 'The maximum total quantity per shipment is ### units.',
    order_has_shipments_with_state_restrictions: 'Order has shipments with state restrictions',
    shipment_summary: 'Shipment Summary',
    choose_location: 'Choose Location',
    save_order_title: 'Would you like to save your current order?',
    save_order_msg: 'You currently have a separate order open. Do you want to save this order as a draft and open this one?',
  }),
  'zh-tw': true,
  zh: true,
  ja: true,
  fr: true,
  de: true,
  ko: true,
  it: true,
  cs: true,
  tr: true,
  pl: true,
  fi: true,
  hu: true,
  sk: true,
  'en-gb': true,
  nb: true,
  es: true,
  nl: true,
  pt: true,
  ru: true,
  sv: true,
});
